import { Link } from "react-router-dom";
import mainLogo from "../assets/images/logo.png";
import fbLogo from "../assets/images/fbLogo.png";

export default function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top shadow-sm">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={mainLogo} className="img-fluid" width={200} alt="TheAgroVerse" />
          </Link>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link
                  to="https://www.facebook.com/theAgroVerse/"
                  className="btn btn-secondary rounded-pill border shadow-sm d-flex align-items-center text-decoration-none"
                >
                  <img src={fbLogo} className="img-fluid" width={20} alt="" />
                  <span className="ms-2 text-white">Facebook Page</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
