import ProdCard from "../components/ProdCard";
import one_one from "../assets/images/cows/1/1.jpg";
import one_two from "../assets/images/cows/1/2.jpg";
import one_three from "../assets/images/cows/1/3.jpg";
import two_one from "../assets/images/cows/2/1.jpg";
import three_one from "../assets/images/cows/3/3_1.jpg";
import three_two from "../assets/images/cows/3/3_2.jpeg";
import three_three from "../assets/images/cows/3/3_3.jpg";
import seven_one from "../assets/images/cows/7/1.jpg";
import seven_two from "../assets/images/cows/7/2.jpg";
import seven_three from "../assets/images/cows/7/3.jpg";
import eight_one from "../assets/images/cows/8/1.jpg";
import eight_two from "../assets/images/cows/8/2.jpg";
import eight_three from "../assets/images/cows/8/3.jpg";
import five_one from "../assets/images/cows/5/1.jpg";
import five_two from "../assets/images/cows/5/2.jpg";
import four_one from "../assets/images/cows/4/1.jpg";
import four_two from "../assets/images/cows/4/2.jpg";
import four_three from "../assets/images/cows/4/3.jpg";
import twelve_one from "../assets/images/cows/12/1.jpg";
import twelve_two from "../assets/images/cows/12/2.jpg";
import six_one from "../assets/images/cows/6/6_1.jpg";
import nine_one from "../assets/images/cows/9/1.jpeg"
import nine_two from "../assets/images/cows/9/2.jpg"
import thirteen_one from "../assets/images/cows/13/1.jpg"
import thirteen_two from "../assets/images/cows/13/2.jpg"

export default function ShopPage() {
  const prodInfo = [
    {
      id: 2,
      prodTitle: "Cow 2024-002",
      prodColor: "Red",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "195 - 205",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,10,000",
      discountPrice: "1,04,000",
      prodImage: two_one,
      meatWeight: "115 - 125",
      soldOut: 1,
      images: [
        two_one,
        'https://youtu.be/Oe0im_2uXaM',
      ],
    },
    {
      id: 5,
      prodTitle: "Cow 2024-005",
      prodColor: "White",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "205 - 215",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,07,500",
      discountPrice: "1,02,500",
      prodImage: five_one,
      meatWeight: "115 - 125",
      soldOut: 0,
      images: [
        five_one,
        five_two,
        'https://youtu.be/GTrd1g6oOGk'
      ],
    },
    {
      id: 3,
      prodTitle: "Cow 2024-003",
      prodColor: "Red",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "270 - 280",
      prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,45,500",
      discountPrice: "1,39,500",
      prodImage: three_three,
      meatWeight: "150 - 155",
      images: [three_three, three_one, three_two],
      soldOut: 0,
    },    
    {
      id: 6,
      prodTitle: "Cow 2024-006",
      prodColor: "Red",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "270 - 280",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,48,500",
      prodImage: six_one,
      meatWeight: "155 - 165",
      soldOut: 1,
      isPriceFixed: true,
      images: [six_one],
    },
    {
      id: 12,
      prodTitle: "Cow 2024-012",
      prodColor: "Black",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "335 - 350",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,71,500",
      discountPrice: "1,63,500",
      prodImage: twelve_one,
      meatWeight: "200 - 210",
      soldOut: 0,
      images: [
        twelve_one,
        twelve_two,
        'https://youtu.be/C0DkzhwHfPk',
      ],
    },
    {
      id: 13,
      prodTitle: "Cow 2024-013",
      prodColor: "White-Gray",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "190 - 205",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,05,000",
      discountPrice: "1,00,999",
      prodImage: thirteen_one,
      meatWeight: "110 - 125",
      soldOut: 1,
      images: [
        thirteen_one,
        thirteen_two,
        'https://youtu.be/Y-6-OY7MBro',
      ],
    },
    {
      id: 7,
      prodTitle: "Cow 2024-007",
      prodColor: "Red Brown",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "270 - 280",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,46,000",
      discountPrice: "1,41,500",
      prodImage: seven_two,
      meatWeight: "155 - 165",
      soldOut: 0,
      images: [
        seven_one,
        seven_two,
        seven_three,
        'https://youtu.be/uVWfm-qVpwo',
      ],
    },    
    {
      id: 9,
      prodTitle: "Cow 2024-009",
      prodColor: "White-Brown",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "190 - 205",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "93,000",
      discountPrice: "89,500",
      prodImage: nine_one,
      meatWeight: "105 - 115",
      soldOut: 1,
      images: [
        nine_one,
        nine_two,
        'https://youtu.be/bI62lwBC2M0',
      ],
    },
    {
      id: 4,
      prodTitle: "Cow 2024-004",
      prodColor: "Red",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "200 - 210",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "101,500",
      discountPrice: "99,999",
      prodImage: four_one,
      meatWeight: "115 - 120",
      soldOut: 1,
      images: [
        four_one,
        four_two,
        four_three,
        'https://youtu.be/3BCIE9SDa80',
      ],
    },
    {
      id: 1,
      prodTitle: "Cow 2024-001",
      prodColor: "White",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "205 - 215",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,08,500",
      discountPrice: "1,02,500",
      prodImage: one_one,
      meatWeight: "110 - 125",
      soldOut: 1,
      images: [
        one_one,
        one_two,
        one_three,
        'https://youtu.be/1mT4pwgS0_0',
      ],
    },
    {
      id: 8,
      prodTitle: "Cow 2024-008",
      prodColor: "Red",
      prodHeight: "10",
      prodLength: "20",
      prodWeight: "270 - 280",
      // prodTeeth: "4",
      // prodDescription: "AKib is such a polite cow!",
      prodPrice: "1,46,500",
      discountPrice: "1,41,500",
      prodImage: eight_one,
      meatWeight: "155 - 165",
      soldOut: 0,
      images: [
        eight_one,
        eight_two,
        eight_three,
        'https://youtu.be/lLezqjyM26w'
      ],
    },
  ];
  return (
    <>
      <div className="py-4 my-3">
        <h1>Shop Agroverse, Shop Green.</h1>
        <p>দি এগ্রোভার্স-এ আপনাকে স্বাগতম, যেখানে আমরা আমাদের গরুর মাংসের গুণমানকে সর্বাধিক গুরুত্ব দিয়ে থাকি। আমরা গরু পালনের জন্য শুধুমাত্র সুষম খাদ্য ও তাজা ঘাস ব্যবহার করি, কোনো ক্ষতিকর গ্রোথ প্রমোটার বা স্টেরয়েড ব্যবহার করি না। এগ্রোভার্স-এ আমরা টেকসই খামার ব্যবস্থাপনার প্রতি প্রতিশ্রুতিবদ্ধ যা আমাদের গরুগুলোকে সুস্থ ও খুশী রাখে, এবং উচ্চমানের পুষ্টিকর ও সুস্বাদু মাংস উৎপাদন করে। প্রাকৃতিক ও নৈতিক খামার ব্যবস্থাপনার প্রতি আমাদের প্রতিশ্রুতিতে যোগ দিন এবং প্রকৃতির দ্বারা তৈরি স্বাদ উপভোগ করুন।</p>
        <p>এগ্রোভার্স-এ চলছে সীমিত সময়ের জন্য <b>ডিসকাউন্ট অফার!</b> অফার লুফে নিতে দ্রুত যোগাযোগ করুন এই নাম্বারে - ০১৯১৪৫৯০৮২০<br/>চট্টগ্রাম শহরে <b>ডেলিভারী চার্জ মাত্র ১৫০০-২০০০ টাকা।</b></p>
        {/* <p>এখানে প্রদ্ত্ত গরুর দাম পুরোপুরি ফিক্সড না। গরু কনফার্ম করার সময় নির্ধারিত মূল্যের ওপর আকর্ষণীয় ছাড় দেওয়া হবে ইনশাআল্লাহ্!<br/>চট্টগ্রাম শহরে ডেলিভারী চার্জ মাত্র ১৫০০-২০০০ টাকা।</p> */}
      </div>
      <div className="row row-cols-1 row-cols-md-3 gy-4">
        {prodInfo.map((prod) => (
          <ProdCard key={prod.id} {...prod} />
        ))}
      </div>
    </>
  );
}
