import "bootstrap/dist/js/bootstrap.bundle.min";

import "./App.css";
import ShopPage from "./pages/ShopPage";
import NotFound from "./pages/NotFound";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";

import ReactGA from "react-ga4";
import { useEffect } from "react";
const TRACKING_ID = "G-NZLR99NTNH";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />}>
      <Route index element={<ShopPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
  }, []);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
