import FsLightbox from "fslightbox-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import whatsappBtn from "../assets/images/whatsappBtn.svg";
import fbLogo from "../assets/images/fbLogo.png";
import Ribbon from "./Ribbon";

export default function ProdCard({
  id,
  prodImage,
  prodTitle,
  prodPrice,
  prodColor,
  prodHeight,
  prodLength,
  prodWeight,
  prodTeeth,
  meatWeight,
  soldOut,
  images = [],
  isPriceFixed = false,
  discountPrice = null,
}) {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <div className="col">
        <div className="card text-center border-0 rounded-4 overflow-hidden shadow-sm h-100">
          {soldOut === 1 && <Ribbon />}
          <img src={prodImage} className="card-img-top img-fluid" alt="..." />
          <div className="card-body py-4">
            <h5 className="card-title fw-semibold">{prodTitle}</h5>
            {!!discountPrice && <p>৳<s>{prodPrice}</s> {discountPrice}</p>}
            {!discountPrice && <p className="card-text mt-4">৳ {prodPrice}</p>}
            <div className="d-flex justify-content-around">
              <button type="button" className="btn btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target={`#staticBackdrop${id}`}>
                See Details
              </button>
              <button className="btn btn-success rounded-pill" onClick={() => setToggler(!toggler)}>
                More Photos
              </button>
            </div>
          </div>
        </div>
      </div>
      <FsLightbox toggler={toggler} sources={images} />

      <div
        className="modal fade"
        id={`staticBackdrop${id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {prodTitle}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <table className="table table-responsive">
                <tbody>
                  <tr>
                    <th scope="row">Color:</th>
                    <td>{prodColor}</td>
                  </tr>
                  {/* <tr>
                <th scope="row">Height:</th>
                <td>{prodHeight} feet</td>
              </tr>
              <tr>
                <th scope="row">Length:</th>
                <td>{prodLength} feet</td>
              </tr> */}
                  <tr>
                    <th scope="row">Live Weight (Estimated):</th>
                    <td>{prodWeight} kg</td>
                  </tr>
                  <tr>
                    <th scope="row">Meat Weight (Estimated):</th>
                    <td>{meatWeight} kg</td>
                  </tr>
                  {/* <tr style={{ borderBottomColor: "transparent" }}>
                <th scope="row">Teeth:</th>
                <td>{prodTeeth}</td>
              </tr> */}
                </tbody>
              </table>
              <div className="bg-light rounded-3 text-center p-4">
                <h5>Contact</h5>
                <div className="row g-3 mt-2 align-items-center justify-content-center">
                  <div className="col-8 col-md-6">
                    <Link
                      target="_blank"
                      to="https://www.facebook.com/theAgroVerse/"
                      className="btn btn-secondary rounded-3 border shadow-sm d-flex align-items-center text-decoration-none"
                    >
                      <img src={fbLogo} className="img-fluid" width={20} alt="" />
                      <span className="ms-2 text-white small">Facebook Page</span>
                    </Link>
                  </div>
                  <div className="col-8 col-md-6">
                    <Link target="_blank" to="https://wa.me/+8801914590820" aria-label="Chat on WhatsApp">
                      <img alt="Chat on WhatsApp" src={whatsappBtn} className="img-fluid" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
